import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated } from '../auth/auth.guard';

import { EncountersComponent } from './encounters/encounters.component';
import { NegotiationsComponent } from './negotiations/negotiations.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OpenMessagesComponent } from './open-messages/open-messages.component';
import { PlayerToPlayerComponent } from './player-to-player/player-to-player.component';
import { RaceToRaceComponent } from './race-to-race/race-to-race.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticated],
    children: [
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
      {
        path: 'player-to-player',
        component: PlayerToPlayerComponent,
      },
      {
        path: 'chatroom',
        component: OpenMessagesComponent,
      },
      {
        path: 'race-to-race',
        component: RaceToRaceComponent,
      },
      {
        path: 'negotiations',
        component: NegotiationsComponent,
      },
      {
        path: 'encounters',
        component: EncountersComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessagesRoutingModule { }
