import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessagesRoutingModule } from './messages-routing.module';
import { EncountersComponent } from './encounters/encounters.component';
import { NegotiationsComponent } from './negotiations/negotiations.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OpenMessagesComponent } from './open-messages/open-messages.component';
import { RaceToRaceComponent } from './race-to-race/race-to-race.component';
import { PlayerToPlayerComponent } from './player-to-player/player-to-player.component';


@NgModule({
  declarations: [
    EncountersComponent,
    NegotiationsComponent,
    NotificationsComponent,
    OpenMessagesComponent,
    RaceToRaceComponent,
    PlayerToPlayerComponent
  ],
  imports: [
    CommonModule,
    MessagesRoutingModule
  ]
})
export class MessagesModule { }
